import { Controller } from '@hotwired/stimulus'
export default class extends Controller {
  static targets = [
    'userEquipments',
    'userId',
    'assets',
    'userEquipmentsSelector',
    'assetSelectorMode',
    'assetsBtn',
    'userEquipmentsBtn',
    'unitRequired',
    'amenityRequired'
  ]

  connect() {
    // On connect, load current_user's equipments and existing reports
    if (this.hasAssetSelectorModeTarget && this.hasAssetsTarget) {
      this.assetSelectorModeTarget.classList.remove('hidden')
      this.assetsTarget.classList.add('hidden')
      this.userEquipmentsTarget.classList.add('hidden')
      this.assetsBtnTarget.classList.remove('btn-primary')
      this.assetsBtnTarget.classList.add('btn-outline-primary')
      this.userEquipmentsBtnTarget.classList.remove('btn-primary')
      this.userEquipmentsBtnTarget.classList.add('btn-outline-primary')
      // If equip or unit is required, show user equip by default
      if (this.amenityRequired || this.unitRequired) {
        this.userEquipmentsTarget.classList.remove('hidden')
        this.userEquipmentsBtnTarget.classList.add('btn-primary')
        this.userEquipmentsBtnTarget.classList.remove('btn-outline-primary')
      }
      this.loadUserEquipments()
    }
  }

  async loadUserEquipments() {
    const data = await this.getUserEquipments(this.user_id)
    if (data.length > 0) {
      if (this.activeTab === 'assets')         { this.toggleAssets() }
      if (this.activeTab === 'userEquipments') { this.toggleUserEquipments() }
      if (this.assetSelectorModeTarget.classList.contains('hidden')) {
        this.assetSelectorModeTarget.classList.remove('hidden')
      }
      // Load user equipments
      this.userEquipmentsSelectorTarget.innerHTML = ''
      this.userEquipmentsSelectorTarget.insertAdjacentHTML('beforeend', `
        <option value="">${window.i18n.t('shared.none')}</option>
      `
      )
      data.forEach(line => {
        this.userEquipmentsSelectorTarget.insertAdjacentHTML('beforeend', `
            <option value="${line.amenity_id}">${line.amenity.name}</option>
          `
        )
      })
    } else {
      // Set assets to active tab
      this.activeTab = 'assets'
      this.toggleAssets()
      // Hide mode selector
      this.assetSelectorModeTarget.classList.add('hidden')
      this.assetsTarget.classList.remove('hidden')
    }
  }

  toggleAssets() {
    this.assetsBtnTarget.classList.add('btn-primary')
    this.assetsBtnTarget.classList.remove('btn-outline-primary')
    this.userEquipmentsBtnTarget.classList.remove('btn-primary')
    this.userEquipmentsBtnTarget.classList.add('btn-outline-primary')
    this.assetsTarget.classList.remove('hidden')
    this.userEquipmentsTarget.classList.add('hidden')
    this.userEquipmentsSelectorTarget.value = ''

    // Remove `required` attribute on user equipment field & add to assets fields
    if (this.amenityRequired) {
      this.assetsTarget.querySelector('#amenityFilter').setAttribute('required', true)
      this.userEquipmentsTarget.querySelector('#user_amenity').removeAttribute('required')
    }
  }

  toggleUserEquipments() {
    this.assetsBtnTarget.classList.remove('btn-primary')
    this.assetsBtnTarget.classList.add('btn-outline-primary')
    this.userEquipmentsBtnTarget.classList.add('btn-primary')
    this.userEquipmentsBtnTarget.classList.remove('btn-outline-primary')
    this.assetsTarget.classList.add('hidden')
    this.userEquipmentsTarget.classList.remove('hidden')

    // Remove `required` attribute on asset fields & add to user equipment field
    if (this.amenityRequired) {
      const amenityField = this.assetsTarget.querySelector('#amenityFilter')
      if (amenityField && amenityField.querySelector('select')) {
        amenityField.querySelector('select').removeAttribute('required')
      }
      this.userEquipmentsTarget.querySelector('#user_amenity').setAttribute('required', true)
    }
  }

  getUserEquipments(user_id) {
    return fetch(`/equipment_usages.json?user_id=${user_id}&stop=nil&unit_id=${this.unitId}`, {
      method:  'GET',
      headers: { 'Content-Type': 'application/json' }
    })
      .then(response => response.json())
      .then(data => {
        return data
      })
  }

  setUserEquipmentsActive() {
    this.activeTab = 'userEquipments'
    this.toggleUserEquipments()
  }

  setAssetsActive() {
    this.activeTab = 'assets'
    this.toggleAssets()
  }

  setUnitId(event) {
    this.unitId = event.target.value
    this.loadUserEquipments()
  }

  get activeTab()       { return this._activeTab   }
  set activeTab(newTab) { this._activeTab = newTab }

  get unitId()          { return this._unitId  }
  set unitId(newId)     { this._unitId = newId }

  get user_id()         { return this.userIdTarget.value }
  get unitRequired()    { return this.unitRequiredTarget.dataset.required === 'true' }
  get amenityRequired() { return this.amenityRequiredTarget.dataset.required === 'true' }

}
