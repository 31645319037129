import { Controller } from '@hotwired/stimulus'
export default class extends Controller {

  static targets = [
    'line'
  ]
  get csrfToken() { return document.head.querySelector(`meta[name="csrf-token"]`).getAttribute("content") }

  unlinkUser(event) {
    var user_id = this.lineTarget.dataset.user;
    var material_location_id = this.lineTarget.dataset.location;
    fetch (`/material_locations/${material_location_id}/remove_user?user_id=${user_id}`, {
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": this.csrfToken
      },
      method: 'DELETE',
    })
      .then(response => response.json())
      .then(data => {
        this.lineTarget.remove()
      })
  }

}
