import { Controller } from '@hotwired/stimulus'

export default class extends Controller {

  connect() {
    if (this.element.dataset.manualLoad === 'true') {
      window.ReactOnRails.reactOnRailsPageLoaded()
    }
  }

}
