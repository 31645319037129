import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
    static targets = [ "invoice_contact",
                       "name",
                       "address",
                       "zip_code",
                        "city",
                        "country",
                        "vat",
                        "delivery_address",
                        "delivery_zip_code",
                        "delivery_city",
                        "delivery_country"
                      ]

    get csrfToken() { return document.head.querySelector(`meta[name="csrf-token"]`).getAttribute("content") }

    updateInvoiceData() {
      const invoice_contact = this.invoice_contactTarget.value
      if (invoice_contact) {
        fetch(`/invoice_contacts/${invoice_contact}.json`,
        {
          method: 'GET',
          headers: {
            "Content-Type": "application/json",
            'X-CSRF_Token': this.csrfToken
          }
        })
        .then(response => response.json())
        .then(data => {
          this.nameTarget.value = data.name
          this.addressTarget.value = data.address
          this.zip_codeTarget.value = data.zip_code
          this.cityTarget.value = data.city
          this.countryTarget.value = data.country
          this.vatTarget.value = data.vat_nb
        })
      }
      else {
        this.clearInvoiceData()
      }
    }

    clearInvoiceData() {
      this.nameTarget.value = ""
      this.addressTarget.value = ""
      this.zip_codeTarget.value = ""
      this.cityTarget.value = ""
      this.countryTarget.value = ""
      this.vatTarget.value = ""
    }

    copyInvoiceAddress() {
      this.delivery_addressTarget.value = this.addressTarget.value
      this.delivery_zip_codeTarget.value = this.zip_codeTarget.value
      this.delivery_cityTarget.value = this.cityTarget.value
      this.delivery_countryTarget.value = this.countryTarget.value
    }

}