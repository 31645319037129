import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['startTask']

  checkForAlert(e) {
    if (this.startTaskTarget.checked && this.startTaskTarget.dataset.ongoingTask == 'true') {
      if (!confirm(window.i18n.t('todo.ongoing_task_warning'))) {
        e.preventDefault()
      }
    }
  }

}
