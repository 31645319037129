import { Controller } from "@hotwired/stimulus"
import { Html5Qrcode } from "html5-qrcode"
import { i18n } from '../../src/config/i18n.js'
export default class extends Controller {
	static targets = ['result', 'scanBtn', 'outputData']

  switch_format() {
    if (this.scanBtnTarget.dataset.height == 250) {
      this.scanBtnTarget.dataset.height = 50
    }
    else {
      this.scanBtnTarget.dataset.height = 250
    }
    this.scan()
  }

  scan() {
    // This method will trigger user permissions
    var height = 250
    if (this.scanBtnTarget.dataset.height) {
      height = this.scanBtnTarget.dataset.height
    }

    Html5Qrcode.getCameras().then(devices => {
      if (devices && devices.length) {
        this.scanBtnTarget.classList.add('hidden')
        // var cameraId = devices[0].id;
        const html5QrCode = new Html5Qrcode(/* element id */ "qr-reader");
        html5QrCode.start(
          { facingMode: "environment" },
          {
            fps: 10,    // Optional, frame per seconds for qr code scanning
            qrbox: { width: 250, height: height }  // Optional, if you want bounded box UI
          },
          (decodedText, decodedResult) => {
            html5QrCode.stop().then((ignore) => {
              // QR Code scanning is stopped.
            }).catch((err) => {
              // Stop failed, handle it.
            });
            this.showResult(decodedText)
          },
          (errorMessage) => {
            // parse error, ignore it.
          }
        )
          .catch((err) => {
          // Start failed, handle it.
          });
      }
    }).catch(err => {
      // handle err
    });
  }

  showResult(res) {
    this.resultTarget.classList.remove('hidden')
    if (res.includes("/qrCodeReader?type=unit&qr_code_id")) {
      this.outputDataTarget.insertAdjacentHTML('afterbegin' , `${i18n.t('unit.unit')}: ${res}`);
      this.outputDataTarget.insertAdjacentHTML('afterbegin' , `<a id="go_btn" class="hidden" href="${res}">GO</a>`);
      document.getElementById('go_btn').click()
    }
    else if (res.includes("/qr?ty=un&qi")) {
      this.outputDataTarget.insertAdjacentHTML('afterbegin' , `${i18n.t('unit.unit')}: ${res}`);
      this.outputDataTarget.insertAdjacentHTML('afterbegin' , `<a id="go_btn" class="hidden" href="${res}">GO</a>`);
      document.getElementById('go_btn').click()
    }
    else if (res.includes("/qrCodeReader?type=amenity&qr_code_id")) {
      this.outputDataTarget.insertAdjacentHTML('afterbegin' , `${i18n.t('amenity.amenity')}: ${res}`);
      this.outputDataTarget.insertAdjacentHTML('afterbegin' , `<a id="go_btn" class="hidden" href="${res}">GO</a>`);
      document.getElementById('go_btn').click()
    }
    else if (res.includes("/qr?ty=am&qi")) {
      this.outputDataTarget.insertAdjacentHTML('afterbegin' , `${i18n.t('amenity.amenity')}: ${res}`);
      this.outputDataTarget.insertAdjacentHTML('afterbegin' , `<a id="go_btn" class="hidden" href="${res}">GO</a>`);
      document.getElementById('go_btn').click()
    }
    else if (res.includes("/qr?ty=mat&qi=")) {
      fetch(`/materials/search_by_field?field=qr_code_id&value=${res.split("/qr?ty=mat&qi=").at(-1)}`,
      {
        method: 'GET',
        headers: {
          "Content-Type": "application/json",
          'X-CSRF_Token': $('meta[name="csrf-token"]').attr('content')
        }
      })
      .then(response => response.json())
      .then(data => {
        if (data.found == "ok"){
          if (document.getElementById('scanned_material_form')) {
            document.getElementById('scanned_material_form').classList.remove('hidden')
            this.outputDataTarget.innerHTML = data.object.name
            document.getElementById('material_id').value = data.object.id
          }
          else {
            this.outputDataTarget.insertAdjacentHTML('afterbegin' , `
              <p class="m-1"> ${i18n.t('material.material')} : ${data.object.name}</p>
              <p class="m-1"> ${i18n.t('material.category')} : ${data.object.category}</p>
              <p class="m-1"> ${i18n.t('material.total_quantity')} : ${data.object.quantity}</p>
            `)


            data.object.material_quantities.forEach((material_quantity) => {
              let lastInventoryDate = material_quantity.inventory_date ? `| ${i18n.t('material.inventory_taken_on')}: ${material_quantity.inventory_date}` : ''
              this.outputDataTarget.insertAdjacentHTML('beforeend' , `
                <li class="ms-4"> ${material_quantity.location_name} : ${material_quantity.quantity} ${lastInventoryDate}</li>`
              );
            })

            this.outputDataTarget.insertAdjacentHTML('beforeend' ,
              `
                <div class="mt-2">
                  <a class="btn btn-primary" data-remote="true" href="/openModal?div=take-material-content&amp;material=${data.object.id}&amp;modal=materials%2Fpartials%2Ftake_material&amp;modal_id=take-material-btn">
                    <i class="fas fa-cart-plus"></i> ${i18n.t('material.actions.take_from_inventory')}
                  </a>
                </div>
                <div class="mt-2">
                  <a class="btn btn-primary" data-remote="true" href="/openModal?div=take-material-content&amp;material=${data.object.id}&amp;modal=materials%2Fpartials%2Fmove_material&amp;modal_id=take-material-btn">
                    <i class="fas fa-arrow-right-arrow-left"></i> ${i18n.t('material.actions.move_from_inventory')}
                  </a>
                </div>
                <div class="mt-2">
                  <a class="btn btn-primary" href="/materials/${data.object.id}">
                    <i class="fas fa-eye"></i> ${i18n.t('actions.see')}
                  </a>
                </div>
                <div class="mt-2">
                  <a class="btn btn-primary" data-remote="true" href="/openModal?div=check-inventory-content&amp;material=${data.object.id}&amp;modal=materials%2Fpartials%2Fcheck_inventory&amp;modal_id=check-inventory-btn">
                    <i class="fas fa-list-check"></i> ${i18n.t('material.actions.make_inventory')}
                  </a>
                </div>
              `
            );
          }
        }
      })
    }
    else {
      $.ajax({
        url : `/search_by_qr_bar_code/`,
        type : 'GET',
        beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
        dataType : 'json',
        data: {link: res}
      })
      .then((data) => {
        if (data.link){
          this.outputDataTarget.insertAdjacentHTML('afterbegin' , `<a id="go_btn" class="hidden" href="${data.link}">GO</a>`);
          document.getElementById('go_btn').click()
        }
        else {
          if (data.hh_link == 'ok') {
            if (document.getElementById('scanned_material_form')){
              document.getElementById('scanned_material_form').classList.remove('hidden')
              this.outputDataTarget.innerHTML = data.object.name
              document.getElementById('material_id').value = data.object.id
            }
            else {
              this.outputDataTarget.insertAdjacentHTML('afterbegin' , `
                  <p> ${i18n.t('material.material')} : ${data.object.name}</p>
                  <p> ${i18n.t('material.category')} : ${data.object.category || ' / ' }</p>
                  <p> ${i18n.t('material.quantity')} : ${data.object.quantity}</p>
                `
              )

              data.object.material_quantities.forEach((material_quantity) => {
                let lastInventoryDate = material_quantity.inventory_date ? `| ${i18n.t('material.inventory_taken_on')}: ${material_quantity.inventory_date}` : ''
                this.outputDataTarget.insertAdjacentHTML('beforeend' , `
                  ${material_quantity.location_name} : ${material_quantity.quantity}  ${lastInventoryDate}<br>`
                );
              })

              this.outputDataTarget.insertAdjacentHTML('beforeend' ,
                `
                  <div class="mt-2">
                    <a class="btn btn-primary" data-remote="true" href="/openModal?div=take-material-content&amp;material=${data.object.id}&amp;modal=materials%2Fpartials%2Ftake_material&amp;modal_id=take-material-btn">
                      <i class="fas fa-cart-plus"></i> ${i18n.t('material.actions.take_from_inventory')}
                    </a>
                  </div>
                  <div class="mt-2">
                    <a class="btn btn-primary" data-remote="true" href="/openModal?div=take-material-content&amp;material=${data.object.id}&amp;modal=materials%2Fpartials%2Fmove_material&amp;modal_id=take-material-btn">
                      <i class="fas fa-arrow-right-arrow-left"></i> ${i18n.t('material.actions.move_from_inventory')}
                    </a>
                  </div>
                  <div class="mt-2">
                    <a class="btn btn-primary" href="/materials/${data.object.id}">
                      <i class="fas fa-eye"></i> ${i18n.t('actions.see')}
                    </a>
                  </div>
                  <div class="mt-2">
                    <a class="btn btn-primary" data-remote="true" href="/openModal?div=check-inventory-content&amp;material=${data.object.id}&amp;modal=materials%2Fpartials%2Fcheck_inventory&amp;modal_id=check-inventory-btn">
                      <i class="fas fa-list-check"></i> ${i18n.t('material.actions.make_inventory')}
                    </a>
                  </div>
                `
              );
            }
          }
          else {
            this.scanBtnTarget.classList.remove('hidden')
            this.outputDataTarget.insertAdjacentHTML('afterbegin' , `${i18n.t('shared.unknown_item_scanned')} : ${res}`);
          }
        }
      })
    }
  }
}
